import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { darkThemeColors, lightThemeColors } from './colors';
export var breakpoints = {
  mobile: 990,
  desktop: 10000
}; // colorVariables depreciate soon,, please use styleVariables color instead

var colorVariables = {
  // Primary
  colorPrimaryBase: '#195AFF',
  // Success
  colorSuccessBg: '#F6FFED',
  colorSuccessBase: '#52C41A',
  // Error
  colorErrorBorder: '#FFA39E',
  colorErrorActive: '#CF1322',
  colorErrorBase: '#FF4D4F',
  // Info
  colorInfoBase: '#1677FF',
  // Backgrounds
  colorBgContainer: '#ffffff',
  colorBgLayout: '#F5F5F5',
  //Shadow
  colorShadow: '#AFB7BB66',
  colorWarningBase: '#FAAD14'
};
export var styleVariables = {
  colors: _objectSpread(_objectSpread({
    gray1: '#ffffff',
    gray2: '#fafafa',
    gray3: '#f2f2f2',
    gray4: '#f0f0f0',
    gray5: '#e9e9e9',
    gray6: '#e3e4e5',
    gray7: '#e3e4e6',
    gray8: '#dadce0',
    gray9: '#d6d6d6',
    gray10: '#9A9A9A',
    gray11: '#616161',
    gray12: '#333333',
    gray13: '#000000',
    grey14: '#e1e5e9',
    grey30: '#F0F3F5',
    grey70: '#80888C',
    grey100: '#1C1E20',
    transparentGray6: 'rgba(0, 0, 0, 0.12)',
    blue1: '#e6f2ff',
    blue2: '#1890ff',
    blue3: '#1a5aff',
    blue4: '#1616b9',
    blue6: '#1A5AFF',
    blue80: '#00407F',
    primary20: '#E2F0FF',
    primary60: '#0B48E5',
    gold1: '#fa8c15',
    gold2: '#ff8a00',
    red1: '#ffccc7',
    red2: '#ff4d4f',
    red3: '#f5222d',
    red4: '#CF1322',
    red5: '#FFF1F0',
    red10: '#FFF3F4',
    red40: '#FF3742',
    red60: '#C70615',
    green1: '#3a8814',
    green2: '#E8FCD5'
  }, colorVariables), {}, {
    light: lightThemeColors,
    dark: darkThemeColors
  }),
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px'
  },
  gap: {
    small: '4px',
    medium: '8px',
    large: '16px'
  },
  borderWidth: {
    small: '1px',
    medium: '2px',
    large: '4px'
  },
  padding: {
    small: '4px',
    medium: '8px',
    large: '16px',
    extraLarge: '24px'
  },
  margin: {
    small: '4px',
    medium: '8px',
    large: '16px'
  },
  font: {
    fontSFProDisplay: 'SF Pro Display',
    fontSizeSmall: '14px',
    fontSizeOriginal: '16px',
    fontSizeMedium: '20px',
    fontSizeLarge: '28px',
    fontSizeSupport: '12px',
    fontWeightNormal: '400',
    fontWeightStrong: '600'
  },
  responsive: {
    mobile: 640,
    tablet: 800,
    largeScreen: 1200
  },
  logo: {
    primary: {
      path: ''
    },
    white: {
      path: ''
    }
  }
};